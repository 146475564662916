<template>
  <div class="orderinfo">
    <div class="content">
      <div class="page-title">订单详情</div>
      <div class="page-header-title">
        <span class="sp"
          ><strong class="color-gray">订单号：</strong>{{ detail.order_bn }}
          <span class="custom-badge">复制</span></span
        >
        <span class="sp"
          ><strong class="color-gray">就诊人：</strong>{{ detail.consignee_name }}</span
        >
      </div>
      <div class="top-tooltip">
        <div style="width: 400px" class="it">产品名称</div>
        <div style="width: 150px" class="it">专家费</div>
        <div style="width: 150px" class="it">操作费</div>
        <div style="width: 150px" class="it">商品总额</div>
        <div style="width: 150px" class="it">订单状态</div>
        <div style="width: 160px" class="it">操作</div>
      </div>
      <div class="info">
        <div class="info-content">
          <div class="table-info" v-for="(item, index) in detail.items" :key="index">
            <img :src="$host + item.image_id" />
            <div class="data">
              <div class="data-title">{{ item.name }}</div>
              <div class="data-num">数量 x {{ item.nums }}</div>
              <div class="data-price">￥{{ item.price }}</div>
            </div>
          </div>
          <div class="table-nums">
            {{ detail.expert_fees ? detail.expert_fees.expert_fees : "0" }}
          </div>
          <div class="table-nums">
            {{ detail.operation_fee ? detail.operation_fee.operation_fee : 0 }}
          </div>
          <div class="table-nums">{{ detail.order_total }}</div>
          <div class="table-nums">{{ detail.order_status }}</div>
          <div class="table-tools">
            <div v-if="detail.status == 's1'" class="tool-opera">找人代付</div>
            <div v-if="detail.status == 's1'" class="tool-opera">立即付款</div>
            <div v-if="detail.status == 's1'" class="tool-opera">取消订单</div>
            <div
              v-if="detail.status == 's5'"
              class="tool-opera"
              @click.stop="toChat(detail.order_bn)"
            >
              立即评价
            </div>
            <div v-if="detail.status == 's3'" class="tool-opera">确认到院</div>
          </div>
        </div>
      </div>
      <div class="more-tool">
        <span style="float: left">会员折扣：</span>{{ detail.user_lv_discount }}
      </div>
      <div class="more-tool"><span style="float: left">优惠券：</span>无</div>
      <div class="more-tool"><span style="float: left">积分：</span>无</div>
      <div class="more-tool">
        预约金：<span style="color: #fd9852">￥{{ detail.earnest_money }}</span
        >，到院再付：<span style="color: #fd9852">￥{{
          (detail.order_total - detail.earnest_money).toFixed(2)
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detail: {},
    };
  },
  methods: {
    getDetail() {
      this.$http
        .order_detail({
          order_bn: this.$route.query.order,
        })
        .then((res) => {
          if (res.code == 1) {
            switch (res.data.status) {
              case "s1":
                res.data.order_status = "待付款";
                break;
              case "s2":
                res.data.order_status = "待发货";
                break;
              case "s3":
                res.data.order_status = "待收货";
                break;
              case "s4":
                res.data.order_status = "已退款";
                break;
              case "s5":
                res.data.order_status = "待评论";
                break;
              case "s6":
                res.data.order_status = "已完成";
                break;
              case "s7":
                res.data.order_status = "已作废";
                break;
            }
            this.detail = res.data;
          }
          console.log(res);
        });
    },
    toChat(order) {
      this.$router.push({
        path: "/order_comment?order=" + order,
      });
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>
<style lang="scss" scope>
@import "./order.scss";
</style>
